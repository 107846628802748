import * as React from "react"

import loadable from "@loadable/component"
import { Image } from "../components/Image/Image"
import Project from "../components/Project/Project"

const Layout = loadable(() => import("@common/Layout/Layout"))
const About = loadable(() => import("@components/About/About"))
const SEO = loadable(() => import("@components/Common/SEO/SEO"))

const ProjectPage = props => {
  const { project } = props.pageContext

  return (
    <>
      <SEO
        title={project.seo.title}
        description={project.seo.description}
      />
      <Layout location={props.location} lang={props.pageContext.langKey}>
        <Project project={project} />
      </Layout>
    </>
  )
}

export default ProjectPage
