import styled from "styled-components"
import {
  PageSectionSubtitle,
  PageSectionTitle,
} from "../../styles/CommonStyles"
import { Image } from "../Image/Image"
import { MOBILE_MAX_WIDTH } from "../../constants"
import { MainTitle } from "../Home/Home.styles"

export const AboutSectionContent = styled.div`
  text-align: right;
  margin-top: 20%;
  padding: 0 64px;
  box-sizing: border-box;

  @media only screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
    padding: 0 24px;
  }
`

export const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 96px);
  margin: 8px auto;

  @media only screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
    height: auto;
  }
`

export const ProjectImage = styled(Image)`
  position: relative;
  object-fit: cover;
  width: 100%;
  height: 100px !important;
`

export const ProjectTitle = styled(MainTitle)`
  text-align: right;
`

export const FloorTitle = styled(PageSectionTitle)`
  text-align: right;
`

export const ProjectDescription = styled(PageSectionSubtitle)`
  text-align: right;
  width: auto;
`

export const ProjectDescriptionContainer = styled.div`
  position: relative;
  max-width: 520px;
  float: right;
`

export const ContactFormContainer = styled.div`
  margin-top: 80px;
`

export const FloorPlanContainer = styled.div`
  position: relative;
  height: calc(100vh - 96px);
  padding: 0 64px;
  box-sizing: border-box;

  @media only screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
    padding: 0 24px;
    padding-bottom: 32px;
    height: auto;
  }
`

export const FloorPlanImage = styled(Image)`
  position: relative;
  object-fit: cover;
  width: 20px;
`

export const FloorPlanImageContainer = styled.div`
  position: relative;
  width: 70%;
  height: 100%;
  padding-right: 64px;
  padding-bottom: 64px;
  box-sizing: border-box;
  float: left;

  @media only screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
    width: 100%;
    float: none;
    padding: 0 24px;
  }
`

export const FloorPlanContent = styled.div`
  width: 30%;
  height: 80%;
  float: right;

  @media only screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
    width: 100%;
    float: none;
    height: auto;
    padding-bottom: 32px;
  }
`
