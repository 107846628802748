import React, { useContext } from "react"
import {
  Clear,
  PageHeder,
  PageSection,
  PageSectionSubtitle,
  PageSectionTitle,
} from "../../styles/CommonStyles"
import {
  AboutSectionContent,
  ImageContainer,
  ProjectDescription,
  ProjectDescriptionContainer,
  ProjectImage,
  ProjectTitle,
  ContactFormContainer,
  FloorPlanContainer,
  FloorPlanImage,
  FloorPlanContent,
  FloorPlanImageContainer,
  FloorTitle,
} from "./Project.styles"
import { ContactForm } from "../ContactForm/ContactForm"
import contactFormContent from "../../content/contact-form.json"
import { Context } from "../../context"

const Project = ({ project }) => {
  const { lang } = useContext(Context)
  const contactFormContentTexts = contactFormContent.content[lang].texts

  return (
    <>
      <PageSection>
        <AboutSectionContent>
          <ProjectTitle>{project.title}</ProjectTitle>
          <ProjectDescriptionContainer>
            <ProjectDescription>{project.description}</ProjectDescription>
          </ProjectDescriptionContainer>
          <Clear />
        </AboutSectionContent>
      </PageSection>
      <PageSection $autoHeight={true}>
        {project.floor_plans.map((plan, index) => (
          <FloorPlanContainer key={`floor-plan-${index}`}>
            <FloorPlanContent>
              <FloorTitle>{plan.title}</FloorTitle>
              <ProjectDescription>{plan.description}</ProjectDescription>
            </FloorPlanContent>
            <FloorPlanImageContainer>
              <FloorPlanImage objectFit={'contain'} image={plan.image.src} alt={plan.image.alt} />
            </FloorPlanImageContainer>
            <Clear />
          </FloorPlanContainer>
        ))}
        <Clear />
        {project.images.map((image, index) => (
          <ImageContainer key={`image-${index}`}>
            <ProjectImage  objectFit={'contain'} image={image.src} alt={image.alt} />
          </ImageContainer>
        ))}
      </PageSection>
      <PageSection $autoHeight={true} $withFooter={true}>
        <PageHeder>
          <PageSectionTitle>{contactFormContentTexts.title}</PageSectionTitle>
          <PageSectionSubtitle>
            {contactFormContentTexts.description}
          </PageSectionSubtitle>
        </PageHeder>
        <ContactFormContainer>
          <ContactForm />
        </ContactFormContainer>
      </PageSection>
    </>
  )
}

export default Project
